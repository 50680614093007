import Css from "./style.module.scss";

import { Button, FormSelect, InputGroup, InputGroupAddon } from "shards-react";
import { DebounceInput, Form } from "lib/common";
import { FiFilter, FiX } from "react-icons/fi";
import { bind } from "decko";
import { connect } from "react-redux";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import DataConstants from "const/DataConstants";
import React, { PureComponent } from "react";

const mapStateToProps = (state) => ({
  activeOrganization: getActiveOrganization(state),
  textsData: getTextsData(state)
});

@connect(mapStateToProps, null)
class ContactsFilter extends PureComponent {
  constructor(props) {
    super(props);

    const { initialValue: { text, type, subType, countryCode } } = this.props;

    this.state = {
      textEnabled: !!text,
      typeEnabled: !!type,
      subTypeEnabled: !!type,
      countryCodeEnabled: !!countryCode,
      text: text || "",
      type: type || "",
      subType: subType || "",
      countryCode: countryCode || ""
    };
  }

  emitChangeEvent() {
    const { textEnabled, typeEnabled, subTypeEnabled, countryCodeEnabled, text, type, subType, countryCode } = this.state;

    const filters = {};

    if (textEnabled) filters.text = text;
    if (typeEnabled) filters.type = type;
    if (subTypeEnabled) filters.subType = subType;
    if (countryCodeEnabled) filters.countryCode = countryCode;
    this.props.onChange(filters);
  }

  @bind
  handleTextInputChange({ target: { value } }) {
    this.setState({ text: value });
  }

  @bind
  handleTextInputInputComplete(value) {
    const text = value.trim();

    this.setState(
      () => ({ text, textEnabled: !!text }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleTypeSelectChange({ target: { value } }) {
    this.setState(
      () => ({ type: value, typeEnabled: !!value }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleSubTypeSelectChange({ target: { value } }) {
    this.setState(
      () => ({ subType: value, subTypeEnabled: !!value }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleCountryCodeSelectChange({ target: { value } }) {
    this.setState(
      () => ({ countryCode: value, countryCodeEnabled: !!value }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleTextButtonClick() {
    this.setState(
      ({ textEnabled, text }) => ({
        textEnabled: !textEnabled,
        text: textEnabled ? "" : text
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleTypeButtonClick() {
    this.setState(
      ({ typeEnabled, type }) => ({
        typeEnabled: !typeEnabled,
        type: typeEnabled ? "" : type
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleSubTypeButtonClick() {
    this.setState(
      ({ subTypeEnabled, subType }) => ({
        subTypeEnabled: !subTypeEnabled,
        subType: subTypeEnabled ? "" : subType
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleToggleCountryCodeButtonClick() {
    this.setState(
      ({ countryCodeEnabled, countryCode }) => ({
        countryCodeEnabled: !countryCodeEnabled,
        countryCode: countryCodeEnabled ? "" : countryCode
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleResetButtonClick() {
    this.setState({
      text: "",
      type: "",
      subType: "",
      countryCode: "",
      textEnabled: false,
      typeEnabled: false,
      subTypeEnabled: false,
      countryCodeEnabled: false
    }, () => this.emitChangeEvent());
  }

  render() {
    const { textsData: { uiTexts, countries }, disabled, contactsData } = this.props;

    const { textEnabled, typeEnabled, subTypeEnabled, countryCodeEnabled, text, type, countryCode } = this.state;

    const recentCountries = [...new Set(contactsData.map((contactData) => contactData.countryCode))];

    const hasFilters = !!(textEnabled || typeEnabled || subTypeEnabled || countryCodeEnabled);

    return (
      <div className={Css.contactsFilter}>
        <Form>
          <InputGroup>
            <DebounceInput
              disabled={disabled}
              value={text}
              placeholder={uiTexts.searchText}
              onChange={this.handleTextInputChange}
              onInputComplete={this.handleTextInputInputComplete} />
            <InputGroupAddon type="append">
              <Button
                theme={textEnabled ? "primary" : "secondary"}
                disabled={disabled || !text}
                onClick={this.handleToggleTextButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <InputGroup>
            <FormSelect
              disabled={disabled}
              placeholder={type ? undefined : ""}
              value={type}
              onChange={this.handleTypeSelectChange}>
              {!type && <option value="">{uiTexts.type}</option>}
              <option value={DataConstants.CONTACT_TYPES.LEGAL_PERSON}>{uiTexts.legalPerson}</option>
              <option value={DataConstants.CONTACT_TYPES.NATURAL_PERSON}>{uiTexts.naturalPerson}</option>
              {type && <option value="">{uiTexts.all}</option>}
            </FormSelect>
            <InputGroupAddon type="append">
              <Button
                theme={typeEnabled ? "primary" : "secondary"}
                disabled={disabled || !type}
                onClick={this.handleToggleTypeButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <InputGroup>
            <FormSelect
              disabled={disabled}
              placeholder={countryCode ? undefined : ""}
              value={countryCode}
              onChange={this.handleCountryCodeSelectChange}>
              {!countryCode && <option value="">{uiTexts.country}</option>}
              {Object.entries(countries)
                .filter(([key]) => recentCountries.includes(key))
                .map(([key, value]) => <option key={key} value={key}>{value}</option>)}
              {countryCode && <option value="">{uiTexts.all}</option>}
            </FormSelect>
            <InputGroupAddon type="append">
              <Button
                theme={countryCodeEnabled ? "primary" : "secondary"}
                disabled={disabled || !countryCode}
                onClick={this.handleToggleCountryCodeButtonClick}>
                <FiFilter />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          {hasFilters && (
            <InputGroup className={Css.resetButtonGroup}>
              <Button theme="danger" outline onClick={this.handleResetButtonClick}>
                <FiX />
                <span>{uiTexts.resetFilters}</span>
              </Button>
            </InputGroup>
          )}
        </Form>
      </div>
    );
  }
}

export default ContactsFilter;
