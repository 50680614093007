import CommonCss from "lib/common/style.module.scss";

import { Button, CardBody } from "shards-react";
import { Card, Page, Preloader } from "lib/common";
import { FiPlusCircle, FiUsers } from "react-icons/fi";
import { bind } from "decko";
import { checkContactsFetching, getContactsData } from "selectors/contacts";
import { connect } from "react-redux";
import { getEnvVars } from "selectors/envVars";
import { getTextsData } from "selectors/texts";
import Constants from "const/Constants";
import ContactsActions from "actions/ContactsActions";
import ContactsFilter from "./lib/ContactsFilter";
import ContactsTable from "./lib/ContactsTable";
import EditContactWindow from "lib/windows/EditContactWindow";
import MediaQuery from "react-responsive";
import PageActionsRow from "lib/common/PageActionsRow";
import PageHeader from "lib/common/PageHeader";
import React, { PureComponent } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

const mapStateToProps = (state) => ({
  fetchingData: checkContactsFetching(state),
  envVars: getEnvVars(state),
  contactsData: getContactsData(state),
  textsData: getTextsData(state)
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (...args) => dispatch(UiActions.showModal(...args)),
  toggleModalContent: (...args) => dispatch(UiActions.toggleModalContent(...args)),
  addNewContact: (...args) => dispatch(ContactsActions.addNewContact(...args)),
  editContact: (...args) => dispatch(ContactsActions.editContact(...args)),
  deleteContact: (...args) => dispatch(ContactsActions.deleteContact(...args)),
  copyContact: (...args) => dispatch(ContactsActions.copyContact(...args)),
  fetchContactsList: (...args) => dispatch(ContactsActions.fetchContactsList(...args))
});

@connect(mapStateToProps, mapDispatchToProps)
class ContactsPage extends PureComponent {
  dataListUpdateIntervalId = null;

  constructor(props) {
    super(props);

    const {
      envVars: { text, type, subType, countryCode }
    } = this.props;

    this.state = {
      filters: { text, type, subType, countryCode },
      addContactWindowOpened: false,
      editContactWindowOpened: false,
      editableContactId: null
    };
  }

  getContactDataById(contactId) {
    return this.props.contactsData.find(({ id }) => {
      return id === contactId;
    });
  }

  componentDidMount() {
    const { contactsData, fetchContactsList } = this.props;

    fetchContactsList(false, !!contactsData.length);
    this.dataListUpdateIntervalId = setInterval(
      this.props.fetchContactsList,
      Constants.DATA_LIST_UPDATE_INTERVAL,
      false,
      true
    );
  }

  componentWillUnmount() {
    clearInterval(this.dataListUpdateIntervalId);
  }

  @bind
  handleContactsFilterChange(filters) {
    this.setState({ filters }, () => {
      this.props.history.replace(`?${Utils.objectToQueryString({ ...filters })}`);
    });
  }

  @bind
  handleAddContactButtonClick() {
    this.props.toggleModalContent(true);
    this.setState({ addContactWindowOpened: true });
  }

  @bind
  handleTableContactCopy(contactId) {
    this.props.copyContact(this.getContactDataById(contactId));
  }

  @bind
  handleTableContactEdit(contactId) {
    this.props.toggleModalContent(true);
    this.setState({ editContactWindowOpened: true, editableContactId: contactId });
  }

  @bind
  handleTableContactDelete(contactId) {
    const {
      textsData: { messages },
      showModal,
      deleteContact
    } = this.props;

    showModal(messages.contactDeleteConfirm, null, true).then((result) => {
      if (result) deleteContact(contactId);
    });
  }

  @bind
  handleAddContactWindowClose(result) {
    const { addNewContact, toggleModalContent } = this.props;

    if (result) {
      addNewContact(result).then((contact) => {
        if (contact && contact.existing) {
          toggleModalContent(true);
          this.setState({ editContactWindowOpened: true, editableContactId: contact.id });
        }
      });
    }
    this.setState({ addContactWindowOpened: false });
  }

  @bind
  handleEditContactWindowClose(result) {
    if (result) {
      const { id: contactId, ...restContactData } = result;

      this.props.editContact(contactId, restContactData);
    }
    this.setState({ editContactWindowOpened: false });
  }

  renderAddButtonBlock() {
    const {
      fetchingData,
      textsData: { uiTexts }
    } = this.props;

    return (
      <div>
        <Button size="sm" disabled={fetchingData} onClick={this.handleAddContactButtonClick}>
          <span>
            <FiPlusCircle />
            <span>{uiTexts.addNewContact}</span>
          </span>
        </Button>
      </div>
    );
  }

  renderNoDataContent() {
    const {
      fetchingData,
      textsData: { uiTexts }
    } = this.props;

    if (fetchingData) return <Preloader />;

    return (
      <div className={CommonCss.noDataContent}>
        <div>
          <div><FiUsers /></div>
          <div>{uiTexts.noContacts}</div>
        </div>
        {this.renderAddButtonBlock()}
      </div>
    );
  }

  render() {
    const { contactsData, fetchingData } = this.props;

    const { filters, addContactWindowOpened, editContactWindowOpened, editableContactId } = this.state;

    let editableContactData = null;

    let filteredContactsData = contactsData;

    if (editableContactId) editableContactData = this.getContactDataById(editableContactId);
    if (filters.text) {
      filteredContactsData = filteredContactsData.filter(({ id, businessId, type, subType, countryCode, ...restData }) => {
        return Object.values(restData).some((value) => {
          return value && `${value}`.toLowerCase().includes(filters.text.trim().toLowerCase());
        });
      });
    }
    if (filters.type) {
      filteredContactsData = filteredContactsData.filter(({ type }) => type === filters.type);
    }
    if (filters.subType) {
      filteredContactsData = filteredContactsData.filter(({ subType }) => subType === filters.subType);
    }
    if (filters.countryCode) {
      filteredContactsData = filteredContactsData.filter(({ countryCode }) => countryCode === filters.countryCode);
    }

    return (
      <Page>
        <PageHeader>{!!filteredContactsData.length && this.renderAddButtonBlock()}</PageHeader>
        <Card>
          <CardBody>
            <PageActionsRow sticky={Object.values(filters).filter(Boolean).length}>
              <ContactsFilter
                contactsData={contactsData}
                initialValue={this.state.filters}
                disabled={fetchingData}
                onChange={this.handleContactsFilterChange} />
            </PageActionsRow>
            {filteredContactsData.length ? (
              <MediaQuery maxWidth={Constants.SIMPLIFIED_LAYOUT_MAX_WIDTH}>
                {(matches) => (
                  <ContactsTable
                    data={filteredContactsData}
                    disabled={fetchingData}
                    simplifiedLayout={!!matches}
                    onContactCopy={this.handleTableContactCopy}
                    onContactEdit={this.handleTableContactEdit}
                    onContactDelete={this.handleTableContactDelete} />
                )}
              </MediaQuery>
            ) : (
              this.renderNoDataContent()
            )}
          </CardBody>
          {addContactWindowOpened && <EditContactWindow onClose={this.handleAddContactWindowClose} />}
          {editContactWindowOpened && <EditContactWindow
            editData={editableContactData}
            onClose={this.handleEditContactWindowClose} />}
        </Card>
      </Page>
    );
  }
}

export default ContactsPage;
