import CommonCss from "lib/common/style.module.scss";

import TableCss from "lib/common/Table/style.module.scss";

import Css from "./style.module.scss";

import { ActionsButtons, AutoTooltip, Table } from "lib/common";
import {
  FiAlertCircle,
  FiAtSign,
  FiBriefcase,
  FiCheckCircle,
  FiGlobe,
  FiHelpCircle,
  FiPhone,
  FiUser
} from "react-icons/fi";
import { bind } from "decko";
import { connect, useSelector } from "react-redux";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { PureComponent, useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const ID_CELL_MAX_WIDTH = 140;

const PHONE_CELL_MAX_WIDTH = 160;

const COUNTRY_CELL_WIDTH = 70;

const CZ_ACTIONS_CELL_WIDTH = 90;

const mapStateToProps = (state) => ({
  activeOrganization: getActiveOrganization(state),
  textsData: getTextsData(state)
});

const FlagIcon = React.memo(({ countryCode }) => {
  const { countries } = useSelector(getTextsData);

  const [loadingError, setLoadingError] = useState(false);

  const flagUrl = useMemo(() => Utils.getFlagIconUrl(countryCode), [countryCode]);

  const countryName = countries[countryCode];

  const handleImageError = useCallback(() => {
    setLoadingError(true);
  }, []);

  return loadingError
    ? <div title={countryName} className={CommonCss.flexCenter}><span>{countryCode}</span></div>
    : <img title={countryName} src={flagUrl} onError={handleImageError} />;
});

@connect(mapStateToProps)
class ContactsTable extends PureComponent {
  get tableColumns() {
    const {
      textsData: { uiTexts, countries },
      simplifiedLayout,
      onContactCopy,
      onContactEdit,
      onContactDelete
    } = this.props;

    return [
      {
        accessor: "name",
        clickable: true,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.name,
        Cell: ({ value, original: { type } }) => {
          return (
            <AutoTooltip>
              {type === DataConstants.CONTACT_TYPES.LEGAL_PERSON ? <FiBriefcase /> : <FiUser />}
              <span>{value || Constants.EMPTY_PLACEHOLDER}</span>
            </AutoTooltip>
          );
        }
      },
      {
        accessor: "idNumber",
        clickable: true,
        maxWidth: ID_CELL_MAX_WIDTH,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.businessId,
        Cell: ({ value }) => <AutoTooltip active={!!value}>{value || Constants.EMPTY_PLACEHOLDER}</AutoTooltip>
      },
      {
        accessor: "vatId",
        clickable: true,
        maxWidth: ID_CELL_MAX_WIDTH,
        className: CommonCss.overflowVisible,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.vatId,
        Cell: ({ value, original: { vatPayer, unreliableVatPayerSince } }) => {
          let icon = null;

          if (value) {
            if (vatPayer === false) icon = <FiAlertCircle className={CommonCss.negativeText} />;
            else if (unreliableVatPayerSince) icon = <FiAlertCircle className={CommonCss.warningText} />;
            else if (vatPayer === true) icon = <FiCheckCircle className={CommonCss.positiveText} />;
            else icon = <FiHelpCircle className={CommonCss.neutralText} />;
          }

          return <AutoTooltip active={!!value}>{icon}<span>{value || Constants.EMPTY_PLACEHOLDER}</span></AutoTooltip>;
        }
      },
      {
        accessor: "phone",
        maxWidth: PHONE_CELL_MAX_WIDTH,
        className: classNames(TableCss.linkCell, CommonCss.overflowVisible),
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.phone,
        Cell: ({ value }) => {
          return (
            <AutoTooltip active={!!value}>
              {value
                ? <a href={`tel:${value}`}>
                  <FiPhone />
                  <span>{value}</span>
                </a>
                : Constants.EMPTY_PLACEHOLDER}
            </AutoTooltip>
          );
        }
      },
      {
        accessor: "email",
        className: classNames(TableCss.linkCell, CommonCss.overflowVisible),
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.email,
        Cell: ({ value }) => {
          return (
            <AutoTooltip active={!!value}>
              {value
                ? <a href={`mailto:${value}`}>
                  <FiAtSign />
                  <span>{value}</span>
                </a>
                : Constants.EMPTY_PLACEHOLDER}
            </AutoTooltip>
          );
        }
      },
      {
        accessor: "id",
        show: !simplifiedLayout,
        className: classNames(TableCss.linkCell, CommonCss.overflowVisible),
        headerClassName: CommonCss.flexStart,
        sortMethod: (contactIdA, contactIdB) => {
          const contactA = this.props.data.find(({ id }) => id === contactIdA) || {};

          const contactB = this.props.data.find(({ id }) => id === contactIdB) || {};

          const addressA = [contactA.street, contactA.city, contactA.state, contactA.zipCode].filter((value) => value);

          const addressB = [contactB.street, contactB.city, contactB.state, contactB.zipCode].filter((value) => value);

          const addressStringA = addressA.join(" ") + (contactA.countryCode
            ? `, ${countries[contactA.countryCode] || contactA.countryCode}`
            : "");

          const addressStringB = addressB.join(" ") + (contactB.countryCode
            ? `, ${countries[contactB.countryCode] || contactB.countryCode}`
            : "");

          return addressStringA.localeCompare(addressStringB);
        },
        Header: uiTexts.address,
        Cell: ({ original: { countryCode, state, city, street, zipCode } }) => {
          const { EMPTY_PLACEHOLDER, GOOGLE_MAPS_SEARCH_URL } = Constants;

          const address = [street, city, state, zipCode].filter((value) => value);

          const addressString = address.join(" ") + (countryCode ? `, ${countries[countryCode] || countryCode}` : "");

          return (
            <AutoTooltip active={!!address.length}>
              {address.length
                ? <a
                  target="_blank"
                  href={countryCode ? `${GOOGLE_MAPS_SEARCH_URL}/${addressString.replace(" ", "+")}` : undefined}>
                  <FiGlobe />
                  <span>{addressString}</span>
                </a>
                : EMPTY_PLACEHOLDER}
            </AutoTooltip>
          );
        }
      },
      {
        accessor: "countryCode",
        clickable: true,
        width: COUNTRY_CELL_WIDTH,
        className: classNames(TableCss.flagCell, CommonCss.flexCenter),
        Header: uiTexts.country,
        sortMethod: (countryCodeA, countryCodeB) => (countries[countryCodeA] || "").localeCompare(countries[countryCodeB] || ""),
        Cell: ({ value }) => value ? <FlagIcon countryCode={value} /> : Constants.EMPTY_PLACEHOLDER
      },
      {
        id: "actions",
        sortable: false,
        width: CZ_ACTIONS_CELL_WIDTH,
        headerClassName: CommonCss.flexStart,
        Header: uiTexts.actions,
        Cell: ({ original: { id } }) => {
          return (
            <ActionsButtons
              itemId={id}
              onCopy={onContactCopy}
              onEdit={onContactEdit}
              onDelete={(Utils.checkIsStagingEnv() || Utils.checkIsDevMode()) && onContactDelete} />
          );
        }
      }
    ];
  }

  @bind
  getTrProps(state, { original: { vatPayer, vatId, unreliableVatPayerSince } }) {
    return {
      ...(vatId && unreliableVatPayerSince ? { "data-warning": "" } : {}),
      ...(vatId && vatPayer === false ? { "data-negative": "" } : {}),
      disabled: this.props.disabled
    };
  }

  @bind
  getTdProps(state, { original: { id } }, { clickable }) {
    const { disabled, onContactEdit } = this.props;

    if (!clickable || disabled) return {};

    return {
      style: { cursor: "pointer" },
      onClick: () => { onContactEdit(id); }
    };
  }

  render() {
    const { data } = this.props;

    return (
      <Table
        showPageSizeOptions
        key={data.length}
        columns={this.tableColumns}
        data={data}
        showPagination={data.length > Constants.TABLE_PAGE_SIZE}
        className={Css.contactsTable}
        getTrProps={this.getTrProps}
        getTdProps={this.getTdProps} />
    );
  }
}

export default ContactsTable;
