import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import * as Yup from "yup";
import { Col, FormGroup, Row } from "shards-react";
import { FiUser, FiUserPlus } from "react-icons/fi";
import { Form, ModalWindow } from "lib/common";
import { checkContactsFetching } from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { useContactBlock } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

const VALIDATION_SCHEMA = Yup.object().shape({
  id: Yup.string().nullable(),
  name: Yup.string().trim().required(),
  type: Yup.string().required(),
  countryCode: Yup.string(),
  idNumber: Yup.string().trim(),
  vatId: Yup.string().trim(),
  phone: Yup.string().trim(),
  email: Yup.string().trim().email(),
  state: Yup.string().trim(),
  city: Yup.string().trim(),
  street: Yup.string().trim(),
  zipCode: Yup.string().trim()
});

const EditContactWindow = ({ editData, contactName, onClose }) => {
  const dispatch = useDispatch();

  const { messages, uiTexts } = useSelector(getTextsData);

  const fetchingData = useSelector(checkContactsFetching);

  const prefillData = useMemo(() => ({ name: contactName }), [contactName]);

  const { state: commonState, ui } = useContactBlock({
    prefillData,
    disabled: fetchingData,
    vendorId: editData && editData.id
  });

  const state = useMemo(() => ({ id: editData ? editData.id : null, ...commonState }), [editData, commonState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialStateSnapshot = useMemo(() => state, []);

  const formValid = useMemo(() => {
    return VALIDATION_SCHEMA.isValidSync(state);
  }, [state]);

  const formHasChanges = useMemo(() => !Utils.checkDeepEquality(state, initialStateSnapshot), [initialStateSnapshot, state]);

  const handleClose = useCallback((result) => {
    (async() => {
      const { prefillIdNumber, ...restState } = state;

      let modalResult = result;

      if (!result && editData && formHasChanges && formValid) {
        modalResult = await dispatch(UiActions.showModal(
          messages.unsavedChangesWarning,
          uiTexts.warning,
          true,
          "sm",
          uiTexts.yes,
          uiTexts.no
        ));
      }
      onClose(modalResult && restState);
    })();
  }, [uiTexts, messages, state, editData, formValid, formHasChanges, dispatch, onClose]);

  return (
    <ModalWindow
      className={Css.editContactWindow}
      config={{
        confirm: true,
        headerText: editData ? uiTexts.editContact : uiTexts.addNewContact,
        okButtonText: editData ? uiTexts.save : uiTexts.add
      }}
      disabledOkButton={!formValid || (editData && !formHasChanges) || fetchingData}
      iconComponent={editData ? FiUser : FiUserPlus}
      onClose={handleClose}>
      <Form>
        {editData
          ? ui.warningMessage && <FormGroup row className={CommonCss.textCenter}>
            <Row form>
              <Col>{ui.warningMessage}</Col>
            </Row>
          </FormGroup>
          : <>
            <FormGroup row className={CommonCss.textCenter}>
              <Row form>
                <Col>
                  <div><span>{messages.prefillContactData}</span></div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup row className={Css.prefillFormGroup}>
              <Row form>
                <Col>{ui.prefillInputGroup}</Col>
              </Row>
            </FormGroup>
            <FormGroup row className={CommonCss.flexCenter}>
              <Row form>
                <Col>{messages.enterDataManually}</Col>
              </Row>
            </FormGroup>
          </>}
        <FormGroup row>
          <Row form>
            <Col>{ui.nameInputGroup}</Col>
          </Row>
          <Row form>
            <Col>{ui.typeSelectGroup}</Col>
            <Col>{ui.countrySelectGroup}</Col>
          </Row>
          <Row form>
            <Col>{ui.idNumberInputGroup}</Col>
            <Col>{ui.stateSelectGroup}</Col>
          </Row>
          <Row form>
            <Col>{ui.vatIdInputGroup}</Col>
            <Col>{ui.cityInputGroup}</Col>
          </Row>
          <Row form>
            <Col>{ui.phoneInputGroup}</Col>
            <Col>{ui.streetInputGroup}</Col>
          </Row>
          <Row form>
            <Col>{ui.emailInputGroup}</Col>
            <Col>{ui.zipCodeInputGroup}</Col>
          </Row>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default React.memo(EditContactWindow);
